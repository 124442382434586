import ErrorIcon from '@mui/icons-material/Error';
import { Typography, Card } from '@mui/material';
import { ReactElement, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import store from '../../common_lib_front/utilities/store';
import style from './inviteGuestNav.module.css';

type InviteGuestNavProps = {
  activeTab?: 'invite' | 'list' | 'fast-pass' | 'fast-pass-list';
  children?: ReactElement | Array<ReactElement>;
};
export default function InviteGuestNav(props: InviteGuestNavProps): ReactElement {
  const { activeTab, children } = props;
  const { featuresConfig } = useContext(CommunityContext);
  const propertyLinkEnabled = featuresConfig?.host?.inviteGuest?.propertyLinkEnabled;
  const [showFastPassFeatures, setShowFastPassFeatures] = useState<boolean>(
    !!store.getState().user?.fastPassUser,
  );
  useEffect(
    () =>
      store.subscribe(() => {
        setShowFastPassFeatures(!!store.getState().user?.fastPassUser);
      }),
    [setShowFastPassFeatures],
  );
  const warningBox = () => {
    return (
      <Card
        sx={{
          marginTop: '20px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <div className={style.alertPic}>
          <ErrorIcon />
        </div>
        <Typography
          style={{
            padding: '8px',
            fontWeight: 900,
          }}
        >
          Consider having guests request their own passes by sending them a link! Visit
          the correct property page and copy the correct pass link.
          <Link
            to={{
              pathname: '/resident/my-rentals',
            }}
            style={{ textDecoration: 'underline' }}
          >
            View Properties
          </Link>
        </Typography>
      </Card>
    );
  };
  return (
    <>
      {propertyLinkEnabled && warningBox()}
      <div className={`${style.innerBox} white `}>
        <div className={style.tabBox}>
          <div className={style.tab}>
            <Link to="/invite-guest">
              <div className={activeTab === 'invite' ? style.active : style.inactive}>
                Invite a Guest
              </div>
            </Link>
            <div
              className={activeTab === 'invite' ? style.tabLineActive : style.tabLine}
            />
          </div>
          <div className={style.tab}>
            <Link to="/invite-guest/guest-list">
              <div className={activeTab === 'list' ? style.active : style.inactive}>
                Guest List
              </div>
            </Link>
            <div className={activeTab === 'list' ? style.tabLineActive : style.tabLine} />
          </div>
          {showFastPassFeatures && (
            <>
              <div className={style.tab}>
                <Link to="/invite-guest/fast-pass">
                  <div
                    className={activeTab === 'fast-pass' ? style.active : style.inactive}
                  >
                    Fast Pass
                  </div>
                </Link>
                <div
                  className={
                    activeTab === 'fast-pass' ? style.tabLineActive : style.tabLine
                  }
                />
              </div>
              <div className={style.tab}>
                <Link to="/invite-guest/fast-pass-list">
                  <div
                    className={
                      activeTab === 'fast-pass-list' ? style.active : style.inactive
                    }
                  >
                    Fast Pass List
                  </div>
                </Link>
                <div
                  className={
                    activeTab === 'fast-pass-list' ? style.tabLineActive : style.tabLine
                  }
                />
              </div>
            </>
          )}
        </div>
        {children}
      </div>
    </>
  );
}
InviteGuestNav.defaultProps = {
  activeTab: undefined,
  children: undefined,
};
