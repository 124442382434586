import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ReactComponent as EditImg } from '../../assets/edit.svg';
import GenericButton from '../../components/genericButton/genericButton';
import { PassPrice } from '../../hooks/useOrderPrice';
import usePassInfo from '../../hooks/usePassInfo';
import PassInfo from '../../types/passInfo';
import { formatDayWithDayName } from '../../utilities/formatDate';
import style from './passSummary.module.css';

type PassSummaryProps = {
  showVehicleInfo: boolean;
  showEditBtn: boolean;
  data: PassInfo;
  // allPasses: PassInfo[];
  idx: number;
  editLink?: string;
  priceInfo?: PassPrice;
  passes?: PassPrice[];
};

export const PassSummary = (props: PassSummaryProps): ReactElement => {
  const { idx, showEditBtn, data, showVehicleInfo, editLink, priceInfo, passes } = props;

  const history = useHistory();
  const { t } = useTranslation();

  const passInfo = usePassInfo({ passInfoId: data.passInfoId });

  const printPassPrice = (idx: number): string => {
    if (passes?.length) {
      return passes?.[idx]?.price === 0 ? 'No Charge' : '$' + passes?.[idx]?.price;
    }
    return priceInfo?.price === 0 ? 'No Charge' : '$' + priceInfo?.price;
  };

  return (
    <div className={style.box} key={idx + 'summary'}>
      <div className={style.titleBox}>
        <h6 className={`${style.title} textColorDark `}>
          {t(`${idx + 1}. ${passInfo?.name}`)}
        </h6>
        <div className={style.editBtn}>
          {showEditBtn ? (
            <GenericButton
              shape="circle"
              icon={() => <EditImg className={style.circleImg} />}
              title=""
              clickHandler={() => {
                if (editLink) {
                  history.push(editLink);
                }
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={style.container}>
        <div className={style.tableBox}>
          <table className={style.table}>
            <thead>
              <tr className={style.trBox}>
                <th className={`${style.tHead} textColorDark `}>{t('Start Date')}</th>
                <th className={`${style.tHead} textColorDark `}>{t('Valid Through')}</th>
              </tr>
            </thead>
            <tbody>
              <tr className={style.trBox}>
                <td className={`${style.tHead}  `}>
                  {formatDayWithDayName(data.startDate)}
                </td>
                <td className={`${style.tHead} `}>
                  {formatDayWithDayName(data.endDate)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {showVehicleInfo ? (
          <div className={style.vehicleInfoBox}>
            <div className={style.titleBox}>
              <h6 className={`${style.title} textColorDark `}>
                {t('Vehicle Information')}
              </h6>
            </div>
            <div className={style.tableBox}>
              <table className={style.table}>
                <tbody>
                  <tr className={style.trBox}>
                    {data?.vehicle?.make ? (
                      <th className={`${style.tHead} textColorDark `}>
                        {t('Vehicle Make')}
                      </th>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.vehicleModel ? (
                      <th className={`${style.tHead} textColorDark `}>
                        {t('Vehicle Model')}
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.make ? (
                      <td className={`${style.tHead} `}>{data?.vehicle?.make}</td>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.vehicleModel ? (
                      <td className={`${style.tHead}`}>{data?.vehicle?.vehicleModel}</td>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.type ? (
                      <th className={`${style.tHeadSpace} textColorDark `}>
                        {t('Vehicle Type')}
                      </th>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.color ? (
                      <th className={`${style.tHeadSpace} textColorDark `}>
                        {t('Vehicle Color')}
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.type ? (
                      <td className={`${style.tHead}`}>{data?.vehicle?.type}</td>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.color ? (
                      <td className={`${style.tHead}`}>{data?.vehicle?.color}</td>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.licensePlate ? (
                      <th className={`${style.tHeadSpace} textColorDark `}>
                        {t('License Plate')}
                      </th>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.licensePlateState ? (
                      <th className={`${style.tHeadSpace} textColorDark `}>
                        {t('License Plate State')}
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.licensePlate ? (
                      <td className={`${style.tHead}`}>{data?.vehicle?.licensePlate}</td>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.licensePlateState ? (
                      <td className={`${style.tHead}`}>
                        {data?.vehicle?.licensePlateState}
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.primaryDriverName ? (
                      <th className={`${style.tHeadSpace} textColorDark `}>
                        {t('Primary Driver Name')}
                      </th>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.licensePrimaryDriver ? (
                      <th className={`${style.tHeadSpace} textColorDark `}>
                        {t("Primary Driver's License #")}
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.primaryDriverName ? (
                      <td className={`${style.tHead}`}>
                        {data?.vehicle?.primaryDriverName}
                      </td>
                    ) : (
                      <></>
                    )}
                    {data?.vehicle?.licensePrimaryDriver ? (
                      <td className={`${style.tHead}`}>
                        {data?.vehicle?.licensePrimaryDriver}
                      </td>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.fleetNumber ? (
                      <th className={`${style.tHeadSpace} textColorDark `}>
                        {t('Vehicle Fleet Number')}
                      </th>
                    ) : (
                      <></>
                    )}
                  </tr>
                  <tr className={style.trBox}>
                    {data?.vehicle?.fleetNumber ? (
                      <td className={`${style.tHead}`}>{data?.vehicle?.fleetNumber}</td>
                    ) : (
                      <></>
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
        <div className={style.addonBox}>
          <table className={style.table}>
            {passInfo?.priceInfo?.addons
              ?.filter((item: any) => data.addons.includes(item.name))
              .map((item: any) => (
                // eslint-disable-next-line react/jsx-key
                <tbody>
                  <tr className={style.trBox}>
                    <td className={`${style.tHead}  `}>{item.name}</td>
                    <td className={`${style.tHead} ${style.addonPrice}`}>
                      {!item.isFree
                        ? `${(item.price < 0 ? '-$' : '+$') + item.price}`
                        : '+$0'}
                    </td>
                  </tr>
                </tbody>
              ))}
          </table>
        </div>
        <div className={style.priceBox}>
          <span className={style.tSubTotal}>Subtotal</span>
          <span>{printPassPrice(idx)}</span>
        </div>
      </div>
    </div>
  );
};
PassSummary.defaultProps = {
  editLink: '',
  priceInfo: undefined,
};

export default PassSummary;
