import { Box, Button, Tab, Tabs } from '@mui/material';
import { ReactElement, useState, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../../../assets/backArrow.svg';
import LoadingDiamonds from '../../../common_lib_front/components/loadingDiamonds/loadingDiamonds';
import UnitBandsGrid from '../../../components/unitBandsGrid/unitBandsGrid';
import { useValidPassInfos } from '../../../hooks/useValidPassInfos';
import useEditRental from '../editRental/useEditRental';
import PropertyDetail from './property-detail';
import usePayments from './usePayments';
import style from './view-rental.module.css';

export default function ViewRental(): ReactElement {
  const { rental, editRental, editAll, rentalUnitId, refetchData } = useEditRental();
  const { paymentInfo, deletePayment, generateSlug, fetchPaymentMethods } = usePayments();
  const [value, setValue] = useState(0);
  const { validPassInfoDatas } = useValidPassInfos({
    portal: 'guest',
  });
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [loading, setLoading] = useState<boolean>(false);
  const handleSave = async () => {
    setLoading(true);
    await editAll();
    setLoading(false);
  };
  const deletePaymentMethod = async () => {
    if (!paymentInfo?.id) return;
    await generateSlug(rentalUnitId, 'host', false);
    await deletePayment(paymentInfo.id, paymentInfo.type);
  };

  const generateRentalSlug = async (
    rentalUnitId: string,
    type: string,
    value: boolean,
  ) => {
    await generateSlug(rentalUnitId, type, value);
    refetchData();
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ pt: 1, p: 2 }}>{children}</Box>}
      </div>
    );
  }
  return (
    <div className={style.container}>
      <div className={`${style.box} white `}>
        <div className={style.infoBox}>
          <div className={`${style.subHeader} mainColor `}>
            <Link to={'/resident/my-rentals'} className={style.arrow_left}>
              <BackArrow />
            </Link>
            <h1 className={`${style.title} textColorWhite `}>{rental?.address}</h1>
          </div>
          <div className={style.topLine}>
            <div className={style.tabContainer}>
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  className={style.tabBtn}
                  value={0}
                  label="Pass Link & Property Detail"
                />
                <Tab className={style.tabBtn} value={1} label="Unit Bands" />
                {/* <Tab className={style.tabBtn} value={1} label="Guest List" /> */}
              </Tabs>
            </div>
            <Button
              sx={{ display: { xs: 'none', sm: 'block' } }}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
          <div>
            <TabPanel value={value} index={0}>
              <div>
                {!rental || loading ? (
                  <LoadingDiamonds />
                ) : (
                  <PropertyDetail
                    validPassInfoDatas={validPassInfoDatas}
                    rentalInfo={rental}
                    paymentInfo={paymentInfo}
                    deletePayment={deletePaymentMethod}
                    rentalUnitId={rentalUnitId}
                    editRental={(obj: any) => editRental(obj, 0)}
                    generateRentalSlug={generateRentalSlug}
                    refetchData={refetchData}
                    fetchPaymentMethods={fetchPaymentMethods}
                  />
                )}
              </div>
            </TabPanel>
          </div>

          {value === 1 && (
            <TabPanel value={value} index={1}>
              <UnitBandsGrid address={rental?.address ?? ''} />
            </TabPanel>
          )}
          <div className={style.saveBtnMbl}>
            <Button
              sx={{ display: { xs: 'block', sm: 'none' } }}
              variant="contained"
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
