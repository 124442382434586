import { Chip } from '@mui/material';
import { ColDef, GroupCellRendererParams } from 'ag-grid-community';
import { useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as ActionImg } from '../../assets/actionIcon.svg';
import { CommunityContext } from '../../common_lib_front/communityConfigs/communityContextProvider';
import { UnitBandWithReservation } from '../../common_lib_front/types/unitBand';
import { formatDate } from '../../common_lib_front/utilities/formatDate';
import dataGridStyle from './dataGrid.module.css';
import style from './unitBandsGrid.module.css';

const formatStatus = (passStatus: string) => {
  if (passStatus === 'inactive') {
    return 'Ready to Scan';
  }
  if (passStatus === 'incomplete') {
    return 'Incomplete';
  }
  if (passStatus === 'incomplete-rental-car') {
    return 'Incomplete Rental Car';
  }
  return passStatus;
};

const statusStyles = (passStatus: string) => {
  if (passStatus === 'active') {
    return dataGridStyle.active;
  }
  if (passStatus === 'inactive') {
    return dataGridStyle.readyToScan;
  }
  if (passStatus === 'incomplete') {
    return dataGridStyle.incomplete;
  }
  if (passStatus === 'expired') {
    return dataGridStyle.expired;
  }
  if (passStatus === 'refunded') {
    return dataGridStyle.refunded;
  }
  if (passStatus === 'incomplete-rental-car') {
    return dataGridStyle.incompleteRentalCar;
  }
  return '';
};

const PassStatus = ({ value }: { value: string }) => (
  <div className={`${dataGridStyle.gridStatusBox} ${statusStyles(value)}`}>
    {formatStatus(value)}
  </div>
);

const openLinkHandler = async (option: string) => {
  if (option === 'viewdownload') {
    // view download logic here
  }
};

const EditCellRender = (action: GroupCellRendererParams<UnitBandWithReservation>) => {
  const { featuresConfig } = useContext(CommunityContext);
  return (
    <div className={dataGridStyle.actionBox}>
      <div onClick={() => openLinkHandler('viewdownload')} className={style.menuItem}>
        <span className={style.itemText}>View/Download</span>
      </div>
    </div>
  );
};

const dateFormatter = ({ value }: { value: string }) => formatDate(new Date(value));

export const defaultColDef: ColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  wrapHeaderText: true,
  minWidth: 100,
};

export default function useColumnDefs(): ColDef<UnitBandWithReservation>[] {
  const { communityId } = useContext(CommunityContext);
  return useMemo(
    () => [
      // Removing the actions as it should be read only. Should that change I will enable it.
      // {
      //   headerName: 'Actions',
      //   cellRenderer: EditCellRender,
      //   pinned: true,
      //   width: 150,
      // },
      {
        headerName: 'Credential Number',
        field: 'credentialNumber',
        resizable: true,
        sortable: true,
        filter: 'text',
        filterParams: {
          selectAllOnMiniFilter: true,
          applyMiniFilterWhileTyping: true,
          buttons: ['clear'],
        },
      },
      {
        headerName: 'Status',
        field: 'currentStatus',
        resizable: true,
        sortable: true,
        filter: 'text',
        filterParams: {
          selectAllOnMiniFilter: true,
          applyMiniFilterWhileTyping: true,
          buttons: ['clear'],
        },
        cellRenderer: (params: { value: React.ReactNode; data: any }) => {
          const status = params.value;
          return (
            <Chip
              label={status ?? 'inactive'}
              style={{
                backgroundColor:
                  status === 'active' ? 'green' : status === null ? 'gray' : undefined,
                color: 'white',
              }}
            />
          );
        },
      },
    ],
    [communityId],
  );
}
