import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';
import {
  UnitBand,
  UnitBandWithReservation,
} from '../../../common_lib_front/types/unitBand';

const GET_UNIT_BANDS_BY_ADDRESS_WITH_ACTIVE_RESERVATIONS = gql`
  query getUnitBandsByAddressWithActiveReservations($addressId: String!) {
    getUnitBandsByAddressWithActiveReservations(addressId: $addressId) {
      success
      error
      data {
        reservation {
          userId
          communityRentalId
          registrationId
          address
          country
          city
          state
          zipCode
          arrivalDate
          departureDate
          numberPets
          numberGuests
          guestNames
          guestCanEdit
          invitedBy {
            userId
            firstName
            lastName
          }
        }
      }
    }
  }
`;

const GET_UNIT_BANDS_BY_ADDRESS = gql`
  query getUnitBandsByAddress($addressId: String!) {
    getUnitBandsByAddress(addressId: $addressId) {
      success
      error
      data {
        unitBandId
        addressId
        credentialNumber
        currentStatus
      }
    }
  }
`;

type useUnitBadsRes = {
  unitBands: Array<UnitBand>;
};
export function useUnitBands(addressId: string): useUnitBadsRes {
  const [alert, setAlert] = useState<string>('');
  const [alertColor, setAlertColor] = useState<'red' | 'green'>('red');
  const [unitBands, setUnitBands] = useState<Array<UnitBand>>([]);
  const [unitBandsWithActiveReservations, setUnitBandsWithActiveReservations] = useState<
    Array<UnitBandWithReservation>
  >([]);

  // Fetch unitBands with active reservations
  const { refetch: unitBandsWithReservationsRefetch, loading: loadingWithReservations } =
    useQuery(GET_UNIT_BANDS_BY_ADDRESS_WITH_ACTIVE_RESERVATIONS, {
      variables: { addressId },
      fetchPolicy: 'network-only',
      onCompleted: d => {
        if (d.getUnitBandsByAddressWithActiveReservations.success) {
          setUnitBandsWithActiveReservations(
            d.getUnitBandsByAddressWithActiveReservations.data,
          );
        }
      },
    });

  // Fetch unitBands
  const { refetch: unitBandsRefetch, loading: loadingUnitBands } = useQuery(
    GET_UNIT_BANDS_BY_ADDRESS,
    {
      variables: { addressId },
      fetchPolicy: 'network-only',
      onCompleted: d => {
        if (d.getUnitBandsByAddress.success) {
          setUnitBands(d.getUnitBandsByAddress.data);
        }
      },
    },
  );

  // Return loading state and results
  return {
    unitBands,
  };
}
